<template>

	<!-- Information Card 2 -->
	<a-card :bordered="false" class="card-info-2 h-full">
		<div class="col-content h-full" style="background-image: url('images/info-card-2.jpg')">
			<div class="card-content">
				<h5>Scope Shop - 私有化站群解决方案</h5>
				<p>为您公司出海业务保驾护航</p>
			</div>
		</div>
	</a-card>
	<!-- / Information Card 2 -->

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>